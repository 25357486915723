import { Flex, TableColumnHeaderProps, Th } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { SvgSpriteConsumer } from '@pluxee-design-system/react';
import { useCallback } from 'react';

interface CustomTh extends Omit<TableColumnHeaderProps, 'onClick'> {
  align?: 'left' | 'right' | 'center';
  isSortable?: boolean;
  label: string;
  onClick?: (sortBy: string) => void;
  sortKey?: string;
  sortOrder?: 'desc' | 'asc';
  sxIcon?: SystemStyleObject;
}

const CustomTh = ({
  align,
  isSortable,
  label,
  onClick,
  sortKey,
  sortOrder,
  sx,
  sxIcon,
  width,
  ...rest
}: CustomTh) => {
  const handleClick = useCallback(() => sortKey && onClick?.(sortKey), [onClick, sortKey]);

  return (
    <Th
      sx={{
        ...sx,
        cursor: isSortable ? 'pointer' : undefined,
        userSelect: isSortable ? 'none' : undefined,
      }}
      onClick={isSortable ? handleClick : undefined}
      width={width}
      {...rest}
    >
      <Flex display="flex" alignItems="center" justifyContent={align}>
        {label}
        {isSortable && (
          <Flex sx={sxIcon}>
            <SvgSpriteConsumer
              svgId={
                sortOrder === 'asc'
                  ? 'clickableSortingUp24'
                  : sortOrder === 'desc'
                    ? 'clickableSortingDown24'
                    : 'clickableSortingNeutral24'
              }
              size={24}
            />
          </Flex>
        )}
      </Flex>
    </Th>
  );
};

export default CustomTh;
