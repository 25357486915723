import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import {
  Checkbox,
  IconButton,
  Icons,
  Illustrations,
  SvgSpriteConsumer,
} from '@pluxee-design-system/react';
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual';
import { FilterValues } from 'common/filters/types';
import Illustration from 'common/Illustration';
import { get } from 'lodash';
import { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import CustomTh from './CustomTh';
import OffsetTr from './OffsetTr';
import TextCell from './TextCell';
import { ColumnType, Selection } from './types';

interface VirtualizedTableProps<FilterState extends FilterValues, Data = any> {
  columns: ColumnType[];
  count?: number;
  filterState: FilterState;
  hasNextPage?: boolean;
  highlightItem?: (item?: Data) => boolean;
  isEmpty?: boolean;
  isNextPageLoading?: boolean;
  isSelectable?: boolean;
  items: Data[];
  itemSize: number;
  loadNextPage?: (startIndex: number, stopIndex: number) => Promise<void>;
  onSelection?: (selectedAll: boolean, selection: Selection) => void;
  onSortBy: (state: FilterState) => void;
  pageSize?: number;
  reachedEndText?: string;
  selectionKey?: keyof Data;
  width?: string | number;
  height?: number;
}

const nop = () => Promise.resolve();
const nopBool = () => false;

// TODO: Create generic VirtualizedView
const VirtualizedTable = <FilterState extends FilterValues, Data = any>({
  columns: allColumns,
  count = 0,
  filterState,
  filterState: { sortBy, sortOrder },
  hasNextPage = false,
  height,
  highlightItem = nopBool,
  // isEmpty = false,
  isNextPageLoading = false,
  isSelectable = false,
  items,
  itemSize,
  loadNextPage = nop,
  onSelection,
  onSortBy,
  pageSize = 20,
  reachedEndText,
  selectionKey,
}: VirtualizedTableProps<FilterState, Data>) => {
  const pageRef = useRef(0);
  const styles = useMultiStyleConfig('Table', { variant: 'infinite' });
  const columns = useMemo(() => allColumns.filter((c) => !c.hidden), [allColumns]);
  // const getColumnWidth = (index: number) => columns[index]?.size;
  const windowScroll = height === undefined;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);
  const hasMountedRef = useRef(false);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Selection>({});
  const selectionSize = Object.keys(selectedItems).length;
  const isSelectAllIndeterminate = selectionSize > 0 && selectionSize !== count;
  const itemCount = hasNextPage ? items.length + pageSize : items.length;

  // Virtualizers
  const windowVirtualizer = useWindowVirtualizer({
    count: itemCount,
    estimateSize: () => itemSize, // 14 -> 3.5rem
    overscan: 1,
    scrollMargin: containerRef.current?.offsetTop ?? 0,
  });
  const virtualizer = useVirtualizer({
    horizontal: false,
    count: itemCount,
    getScrollElement: () => tableBodyRef.current,
    estimateSize: () => itemSize, // 14 -> 3.5rem
    overscan: 1,
    scrollMargin: 0,
  });
  const { getVirtualItems, getTotalSize, options } = windowScroll ? windowVirtualizer : virtualizer;
  // to not load all columns to decrease re-renders
  // const columnVirtualizer = useVirtualizer({
  //   horizontal: true,
  //   count: columns.length,
  //   getScrollElement: () => tableBodyRef.current,
  //   estimateSize: getColumnWidth,
  //   overscan: 2,
  // });

  const totalSize = getTotalSize();
  const virtualRows = getVirtualItems();
  const virtualRowsLength = virtualRows.length;
  // const virtualColumns = columnVirtualizer.getVirtualItems();
  // const columnsTotalSize = columnVirtualizer.getTotalSize();
  // const virtualColumnsLength = virtualColumns.length;
  // Without these paddings, the container would shrink or expand with the mounting and unmounting of rows
  const paddingTop =
    virtualRowsLength > 0 ? virtualRows?.[0]?.start - options.scrollMargin || 0 : 0;
  const paddingBottom =
    virtualRowsLength > 0
      ? options.scrollMargin + totalSize - (virtualRows?.[virtualRowsLength - 1]?.end || 0)
      : 0;
  // const [paddingLeft, paddingRight] =
  //   virtualColumnsLength > 0
  //     ? [virtualColumns[0].start, columnsTotalSize - virtualColumns[virtualColumnsLength - 1].end]
  //     : [0, 0];
  const lastItem = virtualRows?.[virtualRowsLength - 1];

  const toggleSelectAll = useCallback(() => {
    setSelectedAll((p) => !p);
    setSelectedItems({}); // reset selection
  }, [setSelectedAll, setSelectedItems]);

  const toggleSelectItem = useCallback(
    (index: number | string) => {
      setSelectedItems((prevSelectedItems) => {
        const { [index]: itemState, ...rest } = prevSelectedItems;
        /**
         * if isSelectedAll && itemState === undefined -> [index] = false
         * if isSelectedAll && itemState === value -> remove
         * if !isSelectedAll && itemState === undefined -> [index] = true
         * if !isSelectedAll && itemState === value -> remove
         */
        return itemState !== undefined ? { ...rest } : { ...rest, [index]: !isSelectedAll };
      });
    },
    [isSelectedAll, setSelectedItems],
  );

  // Scrolling
  const hasScrollbar =
    (tableHeaderRef?.current?.scrollWidth ?? 0) > (containerRef?.current?.clientWidth ?? 0);
  const syncScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation(); // stops any further handler from being called for the event

    const master = e.target as HTMLDivElement;
    if (tableHeaderRef.current) tableHeaderRef.current.scrollLeft = master?.scrollLeft ?? 0;
  }, []);
  const scrollLeft = useCallback(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollBy({
        left: -1 * ((tableBodyRef.current?.scrollWidth ?? 0) / columns.length),
        behavior: 'smooth',
      });
    }
  }, [columns.length]);
  const scrollRight = useCallback(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollBy({
        left: (tableBodyRef.current?.scrollWidth ?? 0) / columns.length,
        behavior: 'smooth',
      });
    }
  }, [columns.length]);

  useEffect(() => {
    if (count > 0 && count === selectionSize) {
      toggleSelectAll();
    }
  }, [count, selectionSize, toggleSelectAll]);

  useEffect(() => {
    onSelection?.(isSelectedAll, selectedItems);
  }, [isSelectedAll, selectedItems, onSelection]);

  // Each time the filterState prop changed we reset page counter to clear the cache
  useEffect(() => {
    if (containerRef.current && hasMountedRef.current) {
      pageRef.current = 1;
    }
    hasMountedRef.current = true;
  }, [filterState]);

  // Also reset the selection
  useEffect(() => {
    setSelectedAll(false);
    setSelectedItems({});
  }, [filterState, setSelectedAll, setSelectedItems]);

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length],
  );

  // If there are no more items to be displayed, show a text
  const reachedEnd = !(hasNextPage || isNextPageLoading) ? reachedEndText : undefined;
  const hasData = hasNextPage || isNextPageLoading || items.length > 0;

  const handleSortBy = useCallback(
    (sortBy: string) =>
      onSortBy({
        ...filterState,
        sortBy,
        sortOrder:
          filterState.sortBy === sortBy && filterState.sortOrder === 'desc' ? 'asc' : 'desc',
      }),
    [filterState, onSortBy],
  );
  const isOutsideRange = lastItem?.index > items.length;

  useEffect(() => {
    // TODO: maybe add some condition to not load multiple pages at once
    if (isOutsideRange && hasNextPage && !isNextPageLoading) {
      loadNextPage(pageSize * pageRef.current, pageSize * (pageRef.current + 1));
      pageRef.current += 1;
    }
  }, [hasNextPage, isNextPageLoading, isOutsideRange, loadNextPage, pageSize]);

  return (
    <Box position="relative" ref={containerRef} overscrollBehavior="contain">
      <Box position="sticky" top={0} zIndex={1}>
        {windowScroll && hasScrollbar && (
          <>
            <IconButton
              position="absolute"
              top={0}
              left={-8}
              height={12} // styles?.thead?.th?.height
              aria-label="scrollRight"
              icon={<SvgSpriteConsumer key="scrollLeft" size={32} svgId={Icons.CHEVRON_LEFT32} />}
              onClick={scrollLeft}
              size="sm"
              variant="icon"
            />
            <IconButton
              position="absolute"
              top={0}
              right={-8}
              height={12} // styles?.thead?.th?.height
              aria-label="scrollRight"
              icon={<SvgSpriteConsumer key="scrollRight" size={32} svgId={Icons.CHEVRON_RIGHT32} />}
              onClick={scrollRight}
              size="sm"
              variant="icon"
            />
          </>
        )}
        <TableContainer ref={tableHeaderRef} overflowX="hidden" overflowY="hidden">
          <Table overflowWrap="break-word" sx={styles.table}>
            <Thead sx={styles.thead}>
              <Tr data-row="header">
                {isSelectable /*&& paddingLeft === 0*/ && (
                  <Th width={14}>
                    <Checkbox
                      isChecked={isSelectedAll}
                      isIndeterminate={isSelectAllIndeterminate}
                      onChange={toggleSelectAll}
                    />
                  </Th>
                )}
                {/*{paddingLeft > 0 && <th style={{ width: `${paddingLeft}px` }} />}*/}
                {columns.map((column) => {
                  // const column = columns[virtualColumn.index];
                  return (
                    <CustomTh
                      key={column.id}
                      data-column={column.id}
                      align={column.align}
                      isSortable={column.sortable}
                      label={column.name}
                      onClick={handleSortBy}
                      sortKey={column.sortId ?? column.id}
                      sortOrder={sortBy === (column.sortId ?? column.id) ? sortOrder : undefined}
                      sxIcon={styles.sortIconsContainer}
                      width={column.size ?? 'auto'}
                    />
                  );
                })}
                {/*{paddingRight > 0 && <th style={{ width: `${paddingRight}px` }} />}*/}
              </Tr>
            </Thead>
          </Table>
        </TableContainer>
      </Box>
      <TableContainer
        ref={tableBodyRef}
        overflowX="auto"
        overflowY={windowScroll ? 'hidden' : 'auto'}
        height={windowScroll ? undefined : height - 48}
        onScroll={windowScroll ? syncScroll : undefined}
        overscrollBehavior={windowScroll ? undefined : 'contain'}
        sx={{ overflowAnchor: 'none' }} // https://github.com/TanStack/virtual/issues/426
      >
        <Table
          overflowWrap="break-word"
          sx={styles.table}
          height={windowScroll ? undefined : totalSize}
        >
          <Tbody sx={styles.tbody}>
            <OffsetTr
              columns={columns}
              isSelectVisible={isSelectable}
              padding={paddingTop}
              // paddingLeft={paddingLeft}
              // paddingRight={paddingRight}
              // virtualColumns={virtualColumns}
            />
            {virtualRows.map((virtualRow, key) => {
              const row = items[virtualRow.index];
              const rowSelectionKey = selectionKey
                ? (row?.[selectionKey] as string)
                : virtualRow.index;
              const toggleSelect = () => toggleSelectItem(rowSelectionKey);
              const rowClass = `ds-row-${virtualRow.index % 2 === 0 ? 'even' : 'odd'}${
                highlightItem(row) ? ' ds-selected-row' : ''
              }`;
              const isRowLoaded = isItemLoaded(virtualRow.index);
              const isSelected =
                selectedItems[rowSelectionKey] !== undefined
                  ? selectedItems[rowSelectionKey]
                  : isSelectedAll;

              return (
                <Tr
                  key={key}
                  data-index={virtualRow.index}
                  sx={styles.tr}
                  height={`${itemSize}px !important`}
                  className={rowClass}
                >
                  {isSelectable /*&& paddingLeft === 0*/ && (
                    <Td width={14} sx={styles.td}>
                      {isRowLoaded && <Checkbox isChecked={isSelected} onChange={toggleSelect} />}
                      {!isRowLoaded && <Skeleton />}
                    </Td>
                  )}
                  {/*{paddingLeft > 0 && <td style={{ width: `${paddingLeft}px` }} />}*/}
                  {columns.map((column) => {
                    // const column = columns[virtualColumn.index];
                    if (!isRowLoaded) {
                      return (
                        <Td
                          key={column.id}
                          data-column={column.id}
                          sx={styles.td}
                          width={column.size ?? 'auto'}
                        >
                          <Skeleton />
                        </Td>
                      );
                    }

                    const Component = column.component ?? TextCell;
                    const value = get(row, column.id);
                    return (
                      <Td
                        key={column.id}
                        data-column={column.id}
                        sx={styles.td}
                        width={column.size ?? 'auto'}
                        whiteSpace="normal"
                      >
                        <Flex display="flex" alignItems="center" justifyContent={column.align}>
                          <Component extra={column.extra} row={row}>
                            {column.component ? value : value || <>&mdash;</>}
                          </Component>
                        </Flex>
                      </Td>
                    );
                  })}
                  {/*{paddingRight > 0 && <td style={{ width: `${paddingRight}px` }} />}*/}
                </Tr>
              );
            })}
            <OffsetTr
              columns={columns}
              isSelectVisible={isSelectable}
              padding={paddingBottom}
              // paddingLeft={paddingLeft}
              // paddingRight={paddingRight}
              // virtualColumns={virtualColumns}
            />
          </Tbody>
        </Table>
      </TableContainer>

      {!hasData && (
        <Flex sx={styles.notFoundContainer}>
          <Flex direction="column" align="center">
            <Illustration
              width={140}
              height={140}
              illustrationId={Illustrations.NOT_FOUND}
              alt={reachedEnd}
            />
            <Text variant="body.largeBold">{reachedEndText}</Text>
          </Flex>
        </Flex>
      )}
      {hasData && reachedEnd && (
        <Text variant="body.largeBold" p={4} align="center">
          {reachedEndText}
        </Text>
      )}
    </Box>
  );
};

export default VirtualizedTable;
